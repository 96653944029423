angular.module('app')
    .service('selectedDepartment', ["$rootScope", "$q", "authenticatedStatus", function($rootScope, $q, authenticatedStatus) {

        this.setDepartment = function(organisationReference) {

            var department;

            if(!_.isEmpty(organisationReference) && !_.isEmpty($rootScope.organisation) && !_.isEmpty($rootScope.organisation.childOrganisations)) {
                department = _.find($rootScope.organisation.childOrganisations, function(department) {
                    return department.organisationReference === organisationReference;
                });
            }

            if(angular.isDefined(department)) {
                $rootScope.department = department;
                localStorage.setItem('department', angular.toJson(department));
            }
        };

         this.clearDepartment = function() {
             delete $rootScope.department;
             localStorage.removeItem('department');
         };
    }]);